export const CONTRACT_ADDRESS_A_NFT = '0xfffFfFF2c3D15CA09f517d0fC64808e2279ec0B5';
export const CONTRACT_ADDRESS_SUDAO = '0x77777777785CcD99FB5F383F858ABA327582EDcB';
export const CONTRACT_ADDRESS_VE_SUDAO = '0x777777777B9F67D6E234196eB00A58918C1d7487';
export const CONTRACT_ADDRESS_TIMELOCK = '0x777777777B9F67D6E234196eB00A58918C1d7487';
export const CONTRACT_ADDRESS_DISTRIBUTOR = '0xd2DddDd89094E0257AFF9A16d7Ec2CB8415a96D1';

export const CONTRACTS_ETHERSCAN_URL = 'polygonscan.com';
export const NETWORK_TYPE = 'matic';

export const NETWORK_ID = 137;
